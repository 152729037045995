import {Component} from '@angular/core'
import version from '../assets/package.json'

@Component({
  selector: 'spb-root',
  template: `
      <div class="spb-holder">
          <spb-header></spb-header>
          <div class="spb-plain-content">
              <router-outlet></router-outlet>
          </div>
          <div class="spb-filler"></div>
          <spb-footer [license]="true" [version]="version" [copyrightYears]="'2021-2023'"></spb-footer>
      </div>`,
})
export class AppComponent {
  public version = version.version
}
