import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {CUSTOMERS_ROUTE_PATH, HOME_ROUTE_PATH, LOGIN_ROUTE_PATH} from './application/data-types'
import {authGuard} from './application/auth.guard'
import {InterestResolver} from './application/interest.resolver'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_ROUTE_PATH
  },
  {
    path: HOME_ROUTE_PATH,
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: [authGuard],
    resolve: {
      latestInterest: InterestResolver
    }
  },
  {
    path: LOGIN_ROUTE_PATH,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: CUSTOMERS_ROUTE_PATH,
    loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
