import {Injectable} from '@angular/core'
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {ConfigService} from '../services/config.service'
import {SECRET} from '../../environments/secret'

/**
 * Inject to set auth header on all requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * @param configService - Knows if we have a token or not.
   */
  constructor(private configService: ConfigService) {
  }

  /**
   * Intercept all requests and add auth token. We should however
   * do this only on white listed requests so that we do not
   * send our tokens when not needed.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.configService.getAccessToken()
    if (!token) {
      return next.handle(req)
    }

    let headers = req.headers

    /**
     * Assign an x-api-key if the latest rate is going to be collected
     */
    if (req.url.indexOf('/interests') !== -1) {
      headers = headers.set('X-Api-Key', SECRET)
    }

    const authReq = req.clone({
      headers: headers.set('Authorization', `Bearer ${token}`)
    })
    return next.handle(authReq)
  }
}
