import {Injectable} from '@angular/core'

import {InterestService} from '../services/interest.service'
import {filter} from 'rxjs/operators'
import {MatSnackBar} from '@angular/material/snack-bar'
import {TermsTexts} from '../model/terms-texts'
import {LatestInterest} from '@sparbanken-syd/kalpylator'

/**
 * These are needed here and there...
 */

@Injectable({providedIn: 'root'})
export class InterestResolver {
  constructor(
    private interestService: InterestService,
    private snackBar: MatSnackBar
  ) {
  }

  public resolve(): void {
    this.interestService.getLatestInterest()
      .pipe(
        filter((latestInterest: LatestInterest) => {
          if (latestInterest === null) {
            /**
             * Error handling, if the latestValidInterest can't be collected from backend.
             */
            this.snackBar.open('Ett fel har inträffat. Den senaste räntan kunde inte hämtas.', 'OKEJ',
              {
                horizontalPosition: 'center',
                verticalPosition: 'top'
              })
          }
          return latestInterest !== null
        })
      )
      .subscribe(
        {
          next: (i: LatestInterest) => {
            TermsTexts.setLatestInterest(i)
          }
        }
      )
  }
}
